<template>
  <div class="search-food-input">
    <v-autocomplete
      v-model="foodId"
      :loading="loading"
      :items="items"
      item-text="title"
      item-value="id"
      :search-input.sync="search"
      flat
      outlined
      hide-no-data
      hide-details
      :label="label"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SearchFoodInput',

  props: {
    value: {
      type: Number,
    },

    label: {
      type: String,
      default: 'Raw Food',
    },

    errorMessages: {
      type: Array,
    },
  },

  data() {
    return {
      search: '',
      errors: [],
      loading: false,

      foodId: this.value,
    }
  },

  computed: {
    ...mapState({
      items: (state) => state.foods.completed.list,
    }),
  },

  created() {
    this.errors = this.errorMessages
  },

  methods: {
    ...mapActions({
      getDataList: 'foods/getDataList',
    }),

    getValue(value) {
      this.value = value
    },

    async fetchData(self, search, page = 1) {
      if (self.loading) return

      let completed = 1
      let params = { page, search, completed }

      self.loading = true

      await self.getDataList(params)

      self.loading = false
    },
  },

  watch: {
    search(val) {
      val && val !== this.select && debounce(this.fetchData, 600)(this, val)
    },

    value(val) {
      val && debounce(this.fetchData, 600)(this, val)
      this.foodId = val
    },

    foodId(value) {
      this.$emit('change', value)
    },

    errorMessages(errors) {
      this.errors = errors
    },
  },
}
</script>
