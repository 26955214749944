<template>
  <div class="bbr-program--details-form">
    <v-row>
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.title"
              label="Title"
              :error-messages="form.$getError('title')"
              outlined
              flat
            />

            <v-textarea
              v-model="form.description"
              label="Notes"
              hide-details
              outlined
              flat
              rows="3"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.serving_quantity"
              label="Serving"
              :error-messages="form.$getError('serving_quantity')"
              :disabled="form.is_flavor_booster"
              hide-details
              outlined
              flat
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.serving_unit"
              label="Unit"
              :error-messages="form.$getError('serving_unit')"
              :disabled="form.is_flavor_booster"
              hide-details
              outlined
              flat
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="4" class="align-self-stretch">
        <v-card class="mx-auto py-3 px-1 mt-3" outlined>
          <v-card-title class="title"> Category </v-card-title>
          <v-card-text>
            <v-checkbox
              v-model="form.is_alcohol"
              label="Alcohol"
              hide-details
            />

            <v-checkbox
              v-model="form.is_flavor_booster"
              label="Flavor Booster"
              hide-details
            />

            <v-checkbox v-model="form.is_raw" label="Raw" hide-details />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8">
        <v-card class="mx-auto py-5" outlined>
          <v-simple-table class="mx-4">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" colspan="2">
                    <span class="title"> Macros per Serving </span>
                  </th>
                  <th class="text-right">
                    %
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style="width:250px">Calories</td>
                  <td style="width:170px">
                    <v-text-field
                      v-model="caloryValue"
                      :disabled="form.is_flavor_booster"
                      hide-details
                      outlined
                      readonly
                      dense
                      flat
                    />
                  </td>
                  <td />
                </tr>

                <tr>
                  <td>Protein</td>
                  <td>
                    <v-text-field
                      v-model="form.protein"
                      :error-messages="form.$getError('protein')"
                      :disabled="form.is_flavor_booster"
                      hide-details
                      outlined
                      dense
                      flat
                    />
                  </td>
                  <td class="text-right">{{ proteinValue.toFixed(1) }}</td>
                </tr>

                <tr>
                  <td>Fats</td>
                  <td>
                    <v-text-field
                      v-model="form.fats"
                      :error-messages="form.$getError('fats')"
                      :disabled="form.is_flavor_booster"
                      hide-details
                      outlined
                      dense
                      flat
                    />
                  </td>
                  <td class="text-right">{{ fatsValue.toFixed(1) }}</td>
                </tr>

                <tr>
                  <td>Carbs</td>
                  <td>
                    <v-text-field
                      v-model="form.carbs"
                      :error-messages="form.$getError('carbs')"
                      :disabled="form.is_flavor_booster"
                      hide-details
                      outlined
                      dense
                      flat
                    />
                  </td>
                  <td class="text-right">{{ carbsValue.toFixed(1) }}</td>
                </tr>

                <tr>
                  <td>Fibre</td>
                  <td>
                    <v-text-field
                      v-model="form.fibre"
                      :error-messages="form.$getError('form.fibre')"
                      :disabled="form.is_flavor_booster"
                      hide-details
                      outlined
                      dense
                      flat
                    />
                  </td>
                  <td class="text-right" />
                </tr>

                <tr v-if="form.is_alcohol">
                  <td>Ethanol</td>
                  <td>
                    <v-text-field
                      v-model="form.ethanol"
                      :error-messages="form.$getError('form.ethanol')"
                      :disabled="form.is_flavor_booster"
                      hide-details
                      outlined
                      dense
                      flat
                    />
                  </td>
                  <td class="text-right">{{ ethanolValue.toFixed(1) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-row v-if="!!form.is_raw">
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <search-food-input
                  v-model="form.raw.food_id"
                  :value="form.raw.food_id"
                  :label="`Raw Food`"
                  @change="rawFoodChange"
                  :error-messages="form.$getError('raw.food_id')"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.raw.serving_quantity"
                  label="Serving"
                  :error-messages="form.$getError('raw.serving_quantity')"
                  hide-details
                  outlined
                  flat
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.raw.serving_unit"
                  label="Unit"
                  :error-messages="form.$getError('raw.serving_unit')"
                  hide-details
                  outlined
                  flat
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        v-if="form.is_flavor_booster"
        cols="12"
        sm="4"
        class="align-self-stretch"
      >
        <v-card class="mx-auto py-3 px-1" outlined>
          <v-card-title class="title"> Flavour Booster Category </v-card-title>

          <v-card-text>
            <v-checkbox
              v-for="row in categories"
              :key="row.id"
              v-model="form.categories"
              :label="row.name"
              :value="row.id"
              hide-details
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SearchFoodInput from '@/components/elements/foods/SearchFoodInput'
import Form from '@/utils/form'
import { isNaN } from 'lodash'

export default {
  name: 'FoodForm',

  props: {
    formData: {
      type: Form,
      required: true,
    },
  },

  components: {
    SearchFoodInput,
  },

  data() {
    return {
      form: this.formData,
    }
  },

  watch: {
    formData() {
      this.form = this.formData
    },
    caloryValue(val) {
      this.form.calories = val
    },

    'form.is_flavor_booster'(value) {
      if (value) {
        this.form.serving_unit = null
        this.form.serving_quantity = null
      }
    },
  },

  computed: {
    ...mapState({
      categories: (state) => state.foods.flavour_booster.categories,
    }),

    caloryValue() {
      const calories =
        this.form.protein * 4 +
        this.form.fats * 9 +
        this.form.carbs * 4 +
        this.form.ethanol * 7

      return (calories > 0 ? Math.round(calories) : this.form.calories).toFixed(
        2
      )
    },

    proteinValue() {
      let value = ((this.form.protein * 4) / this.caloryValue) * 100
      return value > 0 || !isNaN(value) ? value : 0
    },

    fatsValue() {
      const value = ((this.form.fats * 9) / this.caloryValue) * 100
      return value > 0 || !isNaN(value) ? value : 0
    },

    carbsValue() {
      const value = ((this.form.carbs * 4) / this.caloryValue) * 100
      return value > 0 || !isNaN(value) ? value : 0
    },

    ethanolValue() {
      const value = ((this.form.ethanol * 7) / this.caloryValue) * 100
      return value > 0 || !isNaN(value) ? value : 0
    },
  },

  methods: {
    rawFoodChange(value) {
      this.form.raw.food_id = value
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table {
  tbody {
    tr {
      td {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }

      &:hover {
        &:not(.v-data-table__expanded__content) {
          background: #ffffff !important;
        }
      }
    }
  }
}
</style>
