<template>
  <div class="new-program pb-12">
    <main-app-bar>
      <template v-slot:title>
        <span v-if="$attrs.id">
          {{ selectedData.title }}
        </span>
        <span v-else> Add New Form Entry </span>
      </template>

      <template v-slot:top-actions>
        <v-btn
          color="error"
          @click="deleteFoodFromFoodDatabase"
          :loading="isDeletingFood"
          text
          dark
        >
          <v-icon class="mr-3"> {{ icons.delete }} </v-icon>
          Delete Food
        </v-btn>
      </template>
    </main-app-bar>

    <div class="data-entry mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8" v-if="form">
      <v-row>
        <v-col sm="8">
          <div class="d-flex justify-space-between align-center mb-4">
            <h2 class="mr-auto">
              <span> Food Details </span>
            </h2>
            <v-btn
              class="bg-primary-gradient primary next-button px-12"
              @click="saveAndExit"
              :loading="form.$busy"
              text
              depressed
            >
              Save Food Entry
              <v-icon>{{ icons.save }}</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="8">
          <form autocomplete="off">
            <food-form :form-data="form" />
          </form>
        </v-col>
      </v-row>
    </div>

    <confirm-dialog ref="confirmDelete" />

    <v-toast ref="toast" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import VToast from '@/components/elements/Toast'
import FoodForm from './components/FoodForm'
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mapActions, mapMutations, mapState } from 'vuex'
import Event from '@/services/eventBus'
import { mdiContentSave, mdiTrashCanOutline } from '@mdi/js'
import Form from '@/utils/form'
import { pick, isNull } from 'lodash'

export default {
  name: 'Food',

  components: {
    ConfirmDialog,
    MainAppBar,
    FoodForm,
    VToast,
  },

  data() {
    return {
      isDeletingFood: false,
      icons: {
        save: mdiContentSave,
        delete: mdiTrashCanOutline,
      },
      form: new Form({
        id: this.$attrs.id,
        type: 2, // 1 - Recipe, 2 - Food
        title: null,
        description: null,
        is_raw: false,
        is_alcohol: false,
        is_flavor_booster: false,
        serving_quantity: null,
        serving_unit: null,
        calories: 0,
        protein: 0,
        fats: 0,
        carbs: 0,
        fibre: 0,
        ethanol: 0,
        raw: {
          food_id: null,
          serving_quantity: null,
          serving_unit: null,
        },
        categories: [],
        completed: false,
      }),
    }
  },

  computed: {
    ...mapState({
      selectedData: (state) => state.foods.selectedData,
    }),
  },

  methods: {
    ...mapActions({
      getSingleData: 'foods/getSingleData',
      saveData: 'foods/saveData',
      deleteFood: 'foods/deleteFood',
    }),

    ...mapMutations({
      removeFoodOnList: 'foods/removeFoodOnList',
    }),

    async fetchData(id) {
      let { data } = await this.getSingleData(id)
      this.fillForm(data)
    },

    saveForLater() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.saveData(this.form.$data())
        .then((res) => {
          Event.$emit('update-draft-foods-list')

          this.form.$timeout(() => {
            this.form.$busy = false
            this.$router.push({ name: 'draft.foods' })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
        })
    },

    saveAndExit() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()
      payload.completed = true

      this.saveData(payload)
        .then((res) => {
          Event.$emit('update-active-foods-list')

          this.form.$timeout(() => {
            this.form.$busy = false
            this.$router.push({ name: 'active.foods' })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },

    async deleteFoodFromFoodDatabase() {
      const confirm = await this.$refs.confirmDelete.open(
        'Delete Food',
        'Are you sure you want to remove this food on food database?'
      )

      if (confirm) {
        this.isDeletingFood = true

        let food = this.selectedData

        try {
          await this.deleteFood(food.id)
          await this.removeFoodOnList(food.id)

          setTimeout(() => {
            this.$router.push({ name: 'active.foods' })
          }, 800)
        } catch (e) {
          this.$refs.toast.open({
            color: 'red',
            message: 'Cannot delete food item',
          })
        }

        this.isDeletingFood = false
      }
    },

    fillForm(data) {
      data = pick(data, [
        'id',
        'type',
        'title',
        'description',
        'is_alcohol',
        'is_flavor_booster',
        'is_raw',
        'serving_quantity',
        'serving_unit',
        'calories',
        'protein',
        'fats',
        'carbs',
        'fibre',
        'ethanol',
        'raw',
        'categories',
        'completed',
      ])
      if (isNull(data.raw)) {
        data.raw = {
          food_id: null,
          serving_quantity: null,
          serving_unit: null,
        }
      } else {
        data.raw = {
          food_id: data.raw.id,
          serving_quantity: data.raw.serving_quantity,
          serving_unit: data.raw.serving_unit,
        }
      }

      if (isNull(data.categories)) {
        data.categories = []
      }

      this.form = new Form(data)
    },
  },

  watch: {
    'form.categories'() {
      if (this.form.is_flavor_booster) {
        if (this.form.categories instanceof Array) {
          let total = this.form.categories.length

          if (total > 1) {
            for (let i = 1; i < total; i++) {
              this.form.categories.shift()
            }
          }
        }
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$attrs.id) {
        vm.fetchData(vm.$attrs.id)
      }
    })
  },
}
</script>
